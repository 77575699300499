<template>
  <v-layout row wrap>
    <v-flex xs12 v-if="isLoading">
      <v-progress-linear indeterminate color="green" :active="isLoading"></v-progress-linear>
    </v-flex>
    <v-flex v-else xs10 offset-xs1 class="mb-3">
      <v-card>
        <v-toolbar flat dark dense color="primary">
          <v-toolbar-title>{{ $tc('_ledgerReport') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-layout row wrap class="pa-3">
          <v-flex xs3 px-2>
            <v-menu
              lazy
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              full-width
              :nudge-right="40"
              max-width="290px"
              min-width="290px"
            >
              <!-- https://codepen.io/kzelda/pen/pWOLBp -->
              <v-text-field slot="activator" label="Start Date" v-model="startDate" readonly></v-text-field>
              <v-date-picker v-model="startDate" no-title scrollable actions> </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs3 px-2>
            <v-menu
              lazy
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              full-width
              :nudge-right="40"
              max-width="290px"
              min-width="290px"
            >
              <!-- https://codepen.io/kzelda/pen/pWOLBp -->
              <v-text-field slot="activator" label="End Date" v-model="endDate" readonly></v-text-field>
              <v-date-picker v-model="endDate" no-title scrollable actions> </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs2>
            <v-btn :disabled="runReportBtnDisabled" @click="runReport">{{ $tc('_runReport') }}</v-btn>
          </v-flex>
          <v-flex xs12>
            <v-progress-linear indeterminate color="green" :active="isLoading"></v-progress-linear>
          </v-flex>
          <v-flex xs12 v-if="hasError">
            <v-alert type="warning" :value="hasError"> Problem Generating Report </v-alert>
          </v-flex>
          <v-flex xs12>
            <v-footer>
              {{ $tc('_timezone') + ': ' + currentTimezone }}
            </v-footer>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
    <v-flex xs10 offset-xs1 v-if="showReport">
      <v-card>
        <v-progress-linear v-if="reportLoading" indeterminate color="green" :active="reportLoading"></v-progress-linear>
        <div class="text-xs-right" v-if="!reportLoading">
          <a :href="reportUrl" target="_blank">Open in new window</a>
        </div>
        <iframe
          @load="frameLoaded"
          :src="reportUrl"
          frameborder="0"
          style="overflow: hidden; height: 700px; width: 100%"
          height="700px"
        ></iframe>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { InactivityWatcher } from '@/inactivityWatcher';
import { requestParentToKeepActive } from '@/utils/iframeMessageRequester';

export default {
  apollo: {
    $client: 'rptApolloClient',
  },
  data: () => ({
    date: '',
    startDate: null,
    endDate: null,
    isLoading: false,
    connections: [],
    showReport: false,
    reportUrl: null,
    hasError: false,
    errors: [],
    reportLoading: false,
    inactivityWatcherKeepActive: null,
  }),
  computed: {
    currentTimezone() {
      return this.$store.state.currentOrg.timezone ? this.$store.state.currentOrg.timezone : 'N/A';
    },
    runReportBtnDisabled() {
      return this.startDate === null || this.endDate === null;
    },
  },
  methods: {
    frameLoaded() {
      this.reportLoading = false;
      requestParentToKeepActive('report', false);
      this.inactivityWatcherKeepActive?.dispose();
    },
    async runReport() {
      this.reportLoading = true;
      this.showReport = true;

      // Do some validation here
      const orgId = this.$store.state.currentOrg.id;

      let reportUrl = this.getFunctionsBaseUrl() + `reports/view?type=LedgerReport&orgId=${orgId}`;

      if (this.startDate) {
        reportUrl += `&startDate=${this.startDate}`;
      }

      if (this.endDate) {
        reportUrl += `&endDate=${this.endDate}`;
      }

      this.reportUrl = reportUrl;
      this.reportLoading = true;
      this.showReport = true;

      requestParentToKeepActive('report', true);
      this.inactivityWatcherKeepActive = InactivityWatcher.instance?.keepActive(
        () => this.reportLoading && document.contains(this.$el)
      );
    },
  },
};
</script>
<style scoped>
.header-row {
  text-align: right;
}
.input-row {
  color: blue;
  text-align: right;
}
.total-row {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  font-weight: bold;
  text-align: right;
  height: 25px;
}
</style>
